<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Teaching with Technology Philosophy Statement
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">
                        <mark>Teaching with Technology (Revised)</mark>
                      </h2>
                      <p class="description mt--30">
                        <v-col md="6" sm="6" cols="6" style="float: left">
                          <div class="thumbnail">
                            <img
                              class="w-100"
                              src="../../assets/images/about/teambuilding.jpg"
                              alt="Team Building"
                            />
                          </div>
                        </v-col>
                      </p>
                      <h4><mark>About Me</mark></h4>
                      <p class="description">
                        My name is Cody Squadroni, and I am an Instructor of
                        Computer Science and Web and User Experience at Weber
                        State University. I teach a broad range of topics from
                        game development, programming, user experience, and
                        design. With my interestest in teaching, these areas all
                        have something in common, and that is technology. I have
                        always been interested in technology and staying
                        up-to-date with current technology trends or staying
                        ahead. With my passion for technology, I find it
                        essential to include it in my teaching so students are
                        up-to-date. I hope to use innovative technology in the
                        classroom to improve student success in an academic
                        setting and a professional environment.
                        <mark
                          >I understand that technology isn't required in all
                          aspects of learning and teaching, but in the realm of
                          programming, I feel as though it is necessary.</mark
                        >
                      </p>
                      <h4><mark>My Philosophy</mark></h4>
                      <p>
                        I believe that using technology in the classroom is
                        beneficial to the student and the teacher. One benefit
                        is using technology to improve instructional methods so
                        that students retain the information more efficiently.
                        Another benefit in using technology allows us as
                        teachers to instruct students anywhere around the world
                        and allow the use of asynchronous and synchronous
                        options. In computer science, technology allows a
                        hands-on approach, which I feel is important at any age.
                        <mark
                          >Still, it is also crucial that students who are
                          learning to create technology also use technology to
                          understand because they wouldn't be able to
                          create.</mark
                        >
                      </p>
                      <p>
                        I want my students to succeed, so using technology that
                        they would use in the workforce is valuable to prepare
                        them for professional success. I want my students to
                        understand why a specific technology like JavaScript is
                        used and how to use it. I want my students to build
                        things with hands-on learning to understand the
                        structure of projects and why they are helpful. I want
                        my students to write code they know what is happening in
                        the background. I want my students to be able to write
                        code that is accessible so any user can use their
                        application. I want my students to comprehend
                        programming documentation to be able to stay ahead. I
                        want my students to understand how user experience
                        affects their application, and the proper layout
                        placement and design can make their application better
                        for the user. Lastly, I want my students to collaborate
                        and practice teambuilding because of how significant
                        that can be when building anything.
                      </p>
                      <p>
                        My passion is staying up-to-date with technology and
                        finding new technology to implement in my instruction.
                        The more time I spend staying ahead of the curve; the
                        student will remain ahead of the curve. We focus on
                        JavaScript, and it is a language that changes
                        frequently. According to
                        <strong>
                          <a
                            href="https://insights.stackoverflow.com/survey/2021#most-popular-technologies-language-prof"
                            target="_blank"
                            >Stack Overflow Developer Survey 2021</a
                          ></strong
                        >
                        , JavaScript is the number one language used by
                        professionals. As students become professionals, they
                        will have an in-depth understanding of JavaScript
                        through documentation and multiple hands-on projects,
                        making them ready to enter the fast-paced world of
                        technology.
                      </p>
                      <p>
                        As an instructor, I have used the following technology:
                        <strong>
                          <a href="https://slides.com/" target="_blank"
                            >Slides</a
                          ></strong
                        >,
                        <strong>
                          <a href="https://corp.kaltura.com/" target="_blank"
                            >Kaltura</a
                          ></strong
                        >,
                        <strong>
                          <a href="https://codesandbox.io/" target="_blank"
                            >CodeSandbox</a
                          ></strong
                        >, and
                        <strong>
                          <a href="https://www.instructure.com/" target="_blank"
                            >Canvas</a
                          ></strong
                        >. I use Slides for interactive slide presentations.
                        These slides allow me to embed code into the
                        presentation that can be run and quickly be copied over
                        from the student. It can also show differences between
                        code examples allowing more interaction with the
                        student. Kaltura I use to host my media. It provides
                        video playlists, closed captions, transcripts, and
                        embedded quizzes. CodeSandbox allows me to create a
                        project that students can follow along with or make
                        changes to the code synchronously. Lastly, I use Canvas.
                        Canvas allows me to put all these tools together so that
                        students can work through the content in a structured
                        way. Using all these functionalities allows my students
                        to stay engaged and be hands-on with code examples and
                        building projects. They get to collaborate using
                        synchronous tools and team-build while being hands-on
                        with the code.
                      </p>
                      <p>
                        <mark
                          >As I continue to grow as a learner and, most notably,
                          an educator, I realize that technology is a tool to
                          help assist learning, but it is more than that when it
                          comes to programming. Suppose I were to have two
                          groups of learners who were learning to program, and
                          one group I taught used traditional paper and pencil
                          and the other with technology. In that case, I can
                          guarantee if I asked each group to create an
                          application, the group who learned with technology
                          would significantly outperform the others. This shows
                          the significance of the use of technology in a topic
                          like programming. I'm afraid I disagree with those who
                          say technology is only a tool to assist learning,
                          where there are some cases where it is a must. I
                          understand that not all topics need technology, but to
                          say technology is only a tool is misleading. I don't
                          think the answer is black and white but more color
                          palette with tons of choices. Those who think it is
                          black and white are nervous about technology or don't
                          understand it well enough to incorporate it to create
                          a better learning environment. Those who avoid it are
                          doing a disservice to their students as the world, and
                          the technology it uses moves forward.</mark
                        >
                      </p>
                      <h4><mark>Conclusion</mark></h4>
                      <p>
                        In conclusion, it is essential to define what we want
                        the students to learn and the skills they need to
                        develop to prepare for professional success. As
                        technology changes, I will change with it to make and
                        set goals to help define what is required. I will ask
                        myself, what language(s) are industry professionals
                        looking for? What tools do students need to be
                        successful in the industry? Are my students building
                        enough projects? Do they understand the foundations of
                        programming? Is there something new to help improve the
                        instruction method? I will make the proper adjustments
                        so my goals and the goals of the student are achievable.
                        <mark
                          >I will always put the student first, so if that means
                          I need to put more time into understanding new
                          technology tends to introduce to the students, I will.
                          I feel that making sure students are prepared is the
                          primary goal, and using technology will help that. If
                          I ever come to the point of not using new technology,
                          then I feel as though I have failed as an educator. I
                          feel like keeping this philosophy will benefit the
                          students and prepare them for success in the
                          ever-growing world that is revolved around technology.
                          Having this philosophy statement will allow me always
                          to come back and keep that motivation of moving
                          forward.</mark
                        >
                      </p>
                      <p style="text-align: right !important">
                        (word count: 1,076)
                      </p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5020"
                          >Back to LDTE 5020</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
